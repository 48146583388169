import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import Hero from './hero/hero';
import Register from './registerpanel/register';
import Card from './card/card';
import Agenda from './agenda/agenda';
import RegisterPanel from './registerpanel/registerpanel';
import Footer from './footer/footer';
import ThrottleStyles from './ThrottleStyles';

const LosAngeles = ({ data, location }) => {
  const {
    page: {
      seo,
    },
  } = data;
  return (
    <ThrottleStyles>
      <SEO seo={seo} />
      <Hero child />
      <Register
        name="Los Angeles"
        date="WEDNESDAY, SEPTEMBER 15, 2021"
        time="9:30AM–4:30PM"
        address="Automobile Driving Museum, 610 Lairport St., El Segundo, CA 90245"
      />
      <Card />
      <Agenda />
      <RegisterPanel location={location} child />
      <Footer child />
    </ThrottleStyles>
  );
};

// export const query = graphql`
//   query LosAngelesQuery {
//     page: datoCmsWebsitePage(internalName: { eq: "Homepage" }) {
//       seo: seoMetaTags {
//         ...GatsbyDatoCmsSeoMetaTags
//       }
//     }
//   }
// `;

export default LosAngeles;
